"use client";
import React, { useState } from "react";
import CustomButton from "./Buttons/CustomButton";
import Link from "next/link";

const StartTrialBtn = ({ style }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Link
        target="_blank"
        href={"https://app.signagemint.com/sign-up/"}
        key={"sign-up"}
        aria-label={`sign-up`}
      >
        <CustomButton
          Text={"Try for FREE"}
          PrimaryColorBtn
          onClick={() => setIsOpen(true)}
          style={style}
        />
      </Link>
    </>
  );
};

export default StartTrialBtn;
