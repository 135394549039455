import React from "react";
import DemoButton from "../Demo/DemoButton";
import CustomButton from "../Buttons/CustomButton";
import BannerImage from "@/app/digital-signage-software-for-manufacturing-industry/BannerImage";
import Link from "next/link";

const Manufacturing = () => {
  return (
    <div className="industry_container">
      <div className="industry_left_content">
        <p className="heading">
          Establish Brief Engagement with workforce in One click
        </p>
        <p className="subheading">
          Digital signage has immense potential in improving the workplace
          safety & employee communication level within manufacturing industry
          verticals.
        </p>

        <div className="btn_container">
          <DemoButton PrimaryColorBtn />

          <Link href={"/digital-signage-software-for-manufacturing-industry"}>
            <CustomButton Text={"View More"}></CustomButton>
          </Link>
        </div>
      </div>
      <div className="industry_right_content">
        <div className="slider_bg">
          <BannerImage />
        </div>
      </div>
    </div>
  );
};

export default Manufacturing;
