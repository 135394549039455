"use client";
import { apiService } from "@/services/api.services";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import "./RecentBlog.css";
import Link from "next/link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RecentBlog = ({ FilterKeyword = "-" }) => {
  const [recentBlogList, setRecentBlogList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getRecentBlogWithkeySearch({
          keyword: FilterKeyword,
        });
        if (response.status === 200) {
          setRecentBlogList(response.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "#226DB4" }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    // centerMode: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return recentBlogList?.length ? (
    <>
      <h2 className="feature_subheading">Our recent blogs</h2>

      <div className="blog_container">
        <div className="blog_container_left">
          <p className="blog_content_heading">See what’s next !</p>
          <p className="blog_content">
            News, events, and insights into the ways Signagemint is making work
            faster, easier, and more human with advanced AI.
          </p>
        </div>
        <div className="blog_container_right">
          <Slider {...settings}>
            {recentBlogList?.map((blog, index) => (
              <Link href={`/blog/${blog?.attributes?.url_index}`} key={index}>
                <div className="blog_card" key={index}>
                  <div className="blog_card_img_container">
                    {blog?.attributes?.image?.data?.attributes?.url && (
                      <Image
                        src={`${blog?.attributes?.image?.data?.attributes?.url}`}
                        alt={blog?.attributes?.meta_title ?? "blog_img"}
                        className="blog_card_img"
                        width={340}
                        height={340}
                      />
                    )}
                  </div>
                  <div className="blog_card_content">
                    <p>{blog?.attributes?.title}</p>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    </>
  ) : null;
};

export default RecentBlog;
