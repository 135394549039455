import React from "react";
import DemoButton from "../Demo/DemoButton";
import CustomButton from "../Buttons/CustomButton";
import BannerImage from "@/app/digital-signage-software-for-transportation-supply-chain/BannerImage";
import Link from "next/link";

const Transportation = () => {
  return (
    <div className="industry_container">
      <div className="industry_left_content">
        <p className="heading">
          Powerful Digital Signage for transportation, supply chain & logistics
        </p>
        <p className="subheading">
          Leverage digital signage techies for Transportation, Logistics &
          Supply Chain businesses. It helps in finding relevant information,
          transport updates and improves wayfinding.
        </p>

        <div className="btn_container">
          <DemoButton PrimaryColorBtn />

          <Link
            href={"/digital-signage-software-for-transportation-supply-chain"}
          >
            <CustomButton Text={"View More"}></CustomButton>
          </Link>
        </div>
      </div>
      <div className="industry_right_content">
        <div className="slider_bg">
          <BannerImage />
        </div>
      </div>
    </div>
  );
};

export default Transportation;
