"use client";

import React, { useEffect, useState } from "react";
import { apiService } from "@/services/api.services";
import DemoButton from "../Demo/DemoButton";
import StartTrialBtn from "../StartTrialBtn";
import Image from "next/image";
import Head from "next/head";
import BannerImgWebP from "@/Helper/images/Digital-signage-software-driving-steadfast-results.png";

const Banner = () => {
  const [bannerVideo, setBannerVideo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const utilityDataResponse = await apiService.getAllUtilityData();
        if (
          utilityDataResponse.status === 200 &&
          utilityDataResponse.data?.data?.attributes?.banner_video?.data
            ?.attributes?.url
        ) {
          setBannerVideo(
            `${utilityDataResponse.data?.data?.attributes?.banner_video?.data?.attributes?.url}`
          );
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Head>
        <title>Digital Signage Software Company | Signagemint</title>
        <meta
          name="description"
          content="Signagemint offers effective cloud-based digital signage software that can be used to deploy content including integration, apps, schedules, menu boards and advertising. Connect with experts to know more."
        />
        <link rel="canonical" href="https://signagemint.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://signagemint.com/" />
        <meta property="og:title" content="Digital Signage Software Company | Signagemint" />
        <meta
          property="og:description"
          content="Signagemint offers effective cloud-based digital signage software that can be used to deploy content including integration, apps, schedules, menu boards and advertising. Connect with experts to know more."
        />
        <meta property="og:image" content="https://res.cloudinary.com/diao2msfj/image/upload/v1720089223/og_image2_bxvr9i.png" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="600" />
      </Head>
      <div className="banner_bg">
        <div className="container banner_container header_video">
          <div className="banner_container_left">
            <h1 className="banner_heading header_top">
              Share content & connect successfully with effective digital signage
            </h1>
            <p className="banner_text">
              Manage dynamic content to any screen from anywhere at anytime with
              powerful Signagemint digital signage software.
            </p>
            <div className="banner_btn_container">
              <div>
                <DemoButton />
              </div>
              <StartTrialBtn />
            </div>
          </div>
          <div className="banner_container_right">
            {bannerVideo ? (
              <div className="backgroud_video">
                <Image
                  src={BannerImgWebP}
                  alt="Digital signage system for manufacturing industry"
                  width={644}
                  height={420}
                  className="banner_image"
                  loading="lazy"
                />

                <video
                  autoPlay
                  muted
                  loop
                  width={320}
                  height={210}
                  className="banner_video"
                  alt="Digital Signage Software.mp4"
                  style={{ display: bannerVideo ? "block" : "none" }}
                >
                  <source src={bannerVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="dot-1-position">
          <div className="dot" />
        </div>
        <div className="dot-2-position">
          <div className="jumping-dot" />
        </div>
        <div className="dot-3-position">
          <div className="move-dot" />
        </div>
        <div className="circle-container-position">
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle">
              <div className="dot-sticky" />
            </div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
