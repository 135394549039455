"use client";
import React, { useEffect, useState } from "react";
import "./FAQ.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { apiService } from "@/services/api.services";

const FAQ = ({ PageCategory = "home", Title }) => {
  const [openItem, setOpenItem] = useState(null);
  const [faqList, setFaqList] = useState([]);

  const handleItemClick = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getFAQByCategory({
          page: PageCategory,
        });

        if (response.status === 200) {
          setFaqList(response.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <h2 className="faq_subheading">{Title ? Title : "Signagemint FAQs"}</h2>
      <div className="faq_container">
        {faqList?.map((faq, index) => {
          return (
            <div
              className={`faq_Item ${openItem === index ? "open" : ""}`}
              key={index}
            >
              <div
                className="faq_Item_question"
                onClick={() => handleItemClick(index)}
              >
                <p className="faq_question">{faq?.question}</p>
                {openItem === index ? (
                  <RemoveIcon sx={{ color: "#226DB4", cursor: "pointer" }} />
                ) : (
                  <AddIcon sx={{ color: "#226DB4", cursor: "pointer" }} />
                )}
              </div>
              <span className="faq_answer">{faq?.answer}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FAQ;
