"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import image_1 from "@/Helper/images/Drive engagement.png";
import image_2 from "@/Helper/images/Build stronger teams.png";
import image_3 from "@/Helper/images/Maximize revenue.png";
import "@/app/page.css";

const Feature = () => {
  const [activeSection, setActiveSection] = useState(0);

  const handleSectionClick = (index) => {
    setActiveSection(index);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const nextSection = (activeSection + 1) % 3;
      setActiveSection(nextSection);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [activeSection]);

  return (
    <section className="container">
      <div className="featurelistpage feature_container">
        <h2 className="feature_subheading">
          How Signagemint is impactful to businesses
        </h2>
        <p className="feature_content">
          We are a leading digital signage software professional administering
          dynamic ways to assist businesses with powerful digital screen content
          networks building extensive visual communication.
        </p>
      </div>
      <div className="featureBox_section">
        <div className="featureBox_left">
          <div
            className={`feature_left_card ${activeSection === 0 ? "active" : ""}`}
            onClick={() => handleSectionClick(0)}
          >
            <p className="heading">Drive engagement</p>
            <p className="subheading">
              Engage employees in an innovative way now with powerful digital
              signage. Leverage cloud-based digital signage to craft dynamic
              content.
            </p>
          </div>
          <div
            className={`feature_left_card ${activeSection === 1 ? "active" : ""}`}
            onClick={() => handleSectionClick(1)}
          >
            <p className="heading">Build stronger teams</p>
            <p className="subheading">
              Amplify productivity with effective digital signage software. It
              can manage multiple screens from anywhere at any time.
            </p>
          </div>
          <div
            className={`feature_left_card ${activeSection === 2 ? "active" : ""}`}
            onClick={() => handleSectionClick(2)}
          >
            <p className="heading">Maximize revenue</p>
            <p className="subheading">
              Influence customer purchasing decisions by advanced scheduling of
              dynamic content. Display promotions on screens easily.
            </p>
          </div>
        </div>
        <div className="featureBox_right">
          <div className="pic-holder">
            {activeSection === 0 && (
              <Image
                src={image_1}
                className="pic"
                alt="Drive engagement"
              />
            )}
            {activeSection === 1 && (
              <Image
                src={image_2}
                className="pic"
                alt="Build stronger teams"
              />
            )}
            {activeSection === 2 && (
              <Image
                src={image_3}
                className="pic"
                alt="Maximize revenue"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
