import React from "react";
import DemoButton from "../Demo/DemoButton";
import CustomButton from "../Buttons/CustomButton";
import BannerImage from "@/app/digital-signage-software-for-fitness/BannerImage";
import Link from "next/link";

const Fitness = () => {
  return (
    <div className="industry_container">
      <div className="industry_left_content">
        <p className="heading">
          Enhance your gym's atmosphere with dynamic digital signage,
          effectively promoting your brand.
        </p>
        <p className="subheading">
          Keep your fitness goals digitally active with Signagemint premium
          digital signage solution. Our digital signage solution enables you to
          display class schedules, promote social media and many more
        </p>

        <div className="btn_container">
          <DemoButton PrimaryColorBtn />

          <Link href={"/digital-signage-software-for-fitness"}>
            <CustomButton Text={"View More"}></CustomButton>
          </Link>
        </div>
      </div>
      <div className="industry_right_content">
        <div className="slider_bg">
          <BannerImage />
        </div>
      </div>
    </div>
  );
};

export default Fitness;
