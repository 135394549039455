"use client";
import React, { useState } from "react";
import Healthcare from "./Healthcare";
import Education from "./Education";
import QSR_Chains from "./QSR_Chains";
import Fitness from "./Fitness";
import Manufacturing from "./Manufacturing";
import Retail from "./Retail";
import Corporate from "./Corporate";
import Transportation from "./Transportation";

const IndustrySection = () => {
  const [selectedSection, setSelectedSection] = useState("Healthcare");

  const RenderCurrectSection = (currentSection) => {
    switch (currentSection) {
      case "Healthcare":
        return <Healthcare />;
      case "Education":
        return <Education />;
      case "QSR_Chains":
        return <QSR_Chains />;
      case "Fitness":
        return <Fitness />;
      case "Manufacturing":
        return <Manufacturing />;
      case "Retail":
        return <Retail />;
      case "Corporate":
        return <Corporate />;
      case "Transportation":
        return <Transportation />;

      default:
        return null;
    }
  };
  return (
    <section className="industry_bg">
      <div className="container industry_section">
        <h2 className="feature_subheading industry_heading">
          Unlock the major industries our digital signage excels in
        </h2>
        <p className="feature_content industry_heading">
          Signagemint’s digital signage helps enterprises engage people with
          impactful & dynamic displays. It encourages faster communication &
          increased engagement.
        </p>

        <div className="industry_cat_section">
          <div className="industry_cat_nav">
            <p
              className={selectedSection === "Healthcare" ? "active" : ""}
              onClick={() => setSelectedSection("Healthcare")}
            >
              Healthcare
            </p>
            <p
              className={selectedSection === "Education" ? "active" : ""}
              onClick={() => setSelectedSection("Education")}
            >
              Education
            </p>
            <p
              className={selectedSection === "QSR_Chains" ? "active" : ""}
              onClick={() => setSelectedSection("QSR_Chains")}
            >
              QSR Chains
            </p>
            <p
              className={selectedSection === "Fitness" ? "active" : ""}
              onClick={() => setSelectedSection("Fitness")}
            >
              Fitness
            </p>
            <p
              className={selectedSection === "Manufacturing" ? "active" : ""}
              onClick={() => setSelectedSection("Manufacturing")}
            >
              Manufacturing
            </p>
            <p
              className={selectedSection === "Retail" ? "active" : ""}
              onClick={() => setSelectedSection("Retail")}
            >
              Retail
            </p>
            <p
              className={selectedSection === "Corporate" ? "active" : ""}
              onClick={() => setSelectedSection("Corporate")}
            >
              Corporate
            </p>
            <p
              className={selectedSection === "Transportation" ? "active" : ""}
              onClick={() => setSelectedSection("Transportation")}
            >
              Transportation
            </p>
          </div>

          {RenderCurrectSection(selectedSection)}
        </div>
      </div>
    </section>
  );
};

export default IndustrySection;
