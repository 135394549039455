import React from "react";
import DemoButton from "../Demo/DemoButton";
import CustomButton from "../Buttons/CustomButton";
import BannerImage from "@/app/digital-signage-software-for-qsr/BannerImage";
import Link from "next/link";

const QSR_Chains = () => {
  return (
    <div className="industry_container">
      <div className="industry_left_content">
        <p className="heading">Amplify sales with digital signage menuboard</p>
        <p className="subheading">
          Boost your restaurant business with a dynamic digital menuboard.
          Digital signage offers a perfect platform for promoting daily
          specials, happy hour deals, upcoming events & others
        </p>

        <div className="btn_container">
          <DemoButton PrimaryColorBtn />

          <Link href={"/digital-signage-software-for-qsr"}>
            <CustomButton Text={"View More"}></CustomButton>
          </Link>
        </div>
      </div>
      <div className="industry_right_content">
        <div className="slider_bg">
          <BannerImage />
        </div>
      </div>
    </div>
  );
};

export default QSR_Chains;
