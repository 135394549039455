"use client";
import React from "react";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "@/Helper/images/slider/corporate/Corporate Digital Display Solutions.png";
import slider2 from "@/Helper/images/slider/corporate/Digital signage solutions for corporate.png";
import slider3 from "@/Helper/images/slider/corporate/Digital signage software for corporate.png";
import "@/app/resourcepage.css";

const BannerImage = () => {
  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const slides = [
    { src: slider1, alt: "Corporate Digital Display Solutions" },
    { src: slider2, alt: "Digital signage solutions for corporate" },
    { src: slider3, alt: "Digital signage software for corporate" },
  ];

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className="sliderinnner">
          <Image
            src={slide.src}
            alt={slide.alt}
            width={459}
            height={253}
            className="productbanner_imageslider"
            loading="lazy"
          />
        </div>
      ))}
    </Slider>
  );
};

export default BannerImage;
