'use client'
import React from 'react'
import Image from "next/image";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "@/Helper/images/slider/retail/Digital Signage Software for Retail.png";
import slider2 from "@/Helper/images/slider/retail/Digital Signage Solution for retail.png";
import slider3 from "@/Helper/images/slider/retail/Storefront digital signage software.png";
import '@/app/resourcepage.css';

const BannerImage = () => {
  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const slides = [
    { src: slider1, alt: "Digital Signage Software for Retail" },
    { src: slider2, alt: "Digital Signage Solution for retail" },
    { src: slider3, alt: "Storefront digital signage software" },
  ];

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className='sliderinnner'>
          <Image
            src={slide.src}
            alt={slide.alt}
            width={459}
            height={253}
            className="productbanner_imageslider"
            loading="lazy"
          />
        </div>
      ))}
    </Slider>
  )
}

export default BannerImage;
