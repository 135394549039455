'use client'
import React from 'react'
import Image from "next/image";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "@/Helper/images/slider/healthcare/Healthcare Digital Signage Software.png";
import slider2 from "@/Helper/images/slider/healthcare/Healthcare Digital Signage solution.png";
import slider3 from "@/Helper/images/slider/healthcare/Hospital digital signage solution.png";
import slider4 from "@/Helper/images/slider/healthcare/Digital signage solutions for hospitals.png";
import '@/app/resourcepage.css';

const BannerImage = () => {
  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const slides = [
    {
      src: slider1,
      alt: "Healthcare Digital Signage Software"
    },
    {
      src: slider2,
      alt: "Healthcare Digital Signage solution"
    },
    {
      src: slider3,
      alt: "Hospital digital signage solutions"
    },
    {
      src: slider4,
      alt: "Digital signage solutions for hospitals"
    }
  ];

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className='sliderinnner'>
          <Image
            src={slide.src}
            alt={slide.alt}
            width={459}
            height={253}
            className="productbanner_imageslider"
            loading="lazy"
          />
        </div>
      ))}
    </Slider>
  )
}

export default BannerImage;
